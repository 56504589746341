<template>
  <div class="container mt-5">
    <h3
      data-aos-duration="2000"
      data-aos="fade-right"
      class="mb-4 header-spacing moving-gradient-text fw-bolder"
    >
      JOIN THE COLLECTIVE
    </h3>

    <div class="row mt-5">
      <div class="col">
        <p>
          As a dynamic, international collective of individuals we are always
          looking to expand the collective.
        </p>

        <router-link
          to="/our-collective"
          class="btn moving-gradient-button btn-lg"
          >Our Collective</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
